import { ErrorMessage, Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as Yup from "yup"

import { toast } from "react-toastify";
import { AddFaqs, getFaqs } from "../../../store/actions/AdminAction";

const validationSchema = Yup.object().shape({
  question: Yup.string()
    .required('Required')
    .min(2, 'Question must be at least 2 characters'),
  answer: Yup.string()
    .required('Required')
    .min(5, 'Answer must be at least 5 characters'),
});



const CreatePublicRoomModal = ({ viewVal, setViewVal }) => {
  const dispatch = useDispatch();
  const [roomCategories, setRoomCategories] = useState();
  const [flag, setflag] = useState(true);


 

  // useEffect(() => {
  //   dispatch(getStatics());
  // }, []);

  return (
    <Modal
      show={viewVal}
      onHide={() => {
        setViewVal(false);
        setRoomCategories();
      }}
      size="xl"
      id='add-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal"
    >
     <Modal.Header closeButton onHide={() => setViewVal(false)}></Modal.Header>
      <Modal.Body>
        <h4 className="text-center mb-0 delete_content">Add Question</h4>

        <div className="card-body p-0">
          <div className="basic-form">
            <Formik
              initialValues={{ question:"", answer:""}}
              validationSchema={validationSchema}
              onSubmit={async(values) => {
                console.log(values);
                try{
                   const data = await dispatch(AddFaqs({question:values?.question, answer:values?.answer}));
                   console.log(data);
                   if(data?.data?.status_code === 200){
                    toast.success(data?.data?.message);
                    setViewVal(false);
                    setflag(!flag)
                   }
                }catch(error){
                  console.log(error);
                }
              }}
            >
              {({
               values, handleChange,handleSubmit
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label>Question</label>
                      <input
                        type="text"
                        name="question"
                        className="form-control"
                        placeholder="Type Here..."
                        value={values?.question}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="question" component="div" className="text-danger"/>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Answer</label>
                      <textarea
                        type="text"
                        row="6"
                        name="answer"
                        className="form-control"
                        placeholder="Type Here..."
                        value={values?.answer}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="answer" component="div" className="text-danger"/>
                      
                    </div>
                  
                  </div>

                  <div className="d-flex justify-content-center delete_acc_btns">
                    <button
                      type="submit"
                      className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
                    >
                      Add
                    </button>
                    
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatePublicRoomModal;
