export const modalTitle = {
  userAccount: "Delete User Account",
  driverAccount: "Delete Driver Account",
  deactivateAccount: "Deactivate User Account",
  deactivateDriverAccount:"Deactivate driver Account"
};
export const modalBody = {
  userAccount: "Are you sure you want to delete this account?",
  deactivateAccount: `Are you sure you want to Activate/Deactivate this account?`,
  driverAccount:"Are you sure you want to delete this account?",
  deactivateDriverAccount: `Are you sure you want to Activate/Deactivate this account?`
};
