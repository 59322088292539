import API from "../../services/Api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { START_LOADING, STOP_LOADING } from "../Constant/constant";
// import { LOADING_TOGGLE_ACTION, LOGOUT_ACTION } from '../Constant/constant';
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
var cookies = new Cookies();
export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  cookies.remove("adminToken");
  cookies.remove("name");
  cookies.remove("email");
  sessionStorage.clear("adminToken");
  navigate("/");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
export const Adminlogin = (data, navigate, keepLogin) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const response = await API.post("/auth/login", data);
    if (keepLogin && response?.status == 200) {
      cookies.set("adminToken", response.data.data.token, {
        path: "/",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      cookies.set(
        "name",
        response.data.data.firstName + " " + response.data.data.lastName,
        {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        }
      );
      cookies.set("email", response.data.data.email, {
        path: "/",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      sessionStorage.setItem("adminToken", response.data.data.token);
      sessionStorage.setItem("name", response.data.data.full_name);
      sessionStorage.setItem("email", response.data.data.email);
      window.location.href = "/dashboard";
      dispatch({ type: STOP_LOADING, payload: false });
    } else if (!keepLogin && response?.status == 200) {
      sessionStorage.setItem("adminToken", response.data.data.token);
      sessionStorage.setItem(
        "name",
        response.data.data.full_name
      );
      sessionStorage.setItem("email", response.data.data.email);
      window.location.href = "/dashboard";
    } else {
      toast.error(response.data.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const changePasswordAdmin = (data) => async () => {
  const res = await API.put("/changePass", data);
  if (res?.data.success) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};
