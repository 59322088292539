import React from "react";
import Layout from "../components/layouts/Layout";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { changePasswordAdmin } from "../../store/actions/AuthActions";
const ChangePassword = () => {
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.new_password) {
      errors.new_password = "Required";
    } else if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Password Doesn't match";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validate,

    onSubmit: (values) => {
      if (values.new_password == values.confirm_password) {
        dispatch(
          changePasswordAdmin({ newpassword: values?.confirm_password })
        );
      }
    },
  });
  return (
    <Layout>
      <div className="card-body mt-5">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <a href="index.html">
                          <img src="images/logo/logo-full.png" alt="" />
                        </a>
                      </div>
                      <h4 className="text-center mb-4">Change Password</h4>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>New Password</strong>
                          </label>
                          <input
                            type="password"
                            name="new_password"
                            className="form-control"
                            onChange={formik.handleChange}
                            value={formik.values.new_password}
                          />
                          {formik.errors.new_password && (
                            <div className="text-danger fs-12">
                              {formik.errors.new_password}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>Confirm Password</strong>
                          </label>
                          <input
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                            name="confirm_password"
                            type="password"
                            className="form-control"
                          />
                          {formik.errors.confirm_password && (
                            <div className="text-danger fs-12">
                              {formik.errors.confirm_password}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
