import {
  CHANGE_THEME_MODE,
  GET_ALL_USERS,
  GET_PUBLIC_ROOMS,
  GET_STATICS,
  GET_SUPPORTS,
  VIEW_USER_DETAIL,
  GET_SUPPORT_DETAILS,
  DASHBOARD,
  GET_ALL_DRIVERS,
  VIEW_DRIVER_DETAIL,
} from "../Constant/constant";

let initialstate = {
  userList: null,
  totalUser: null,
  userDetail: null,
  statics: null,
  publicRooms: null,
  totalRooms: null,
  themeMode: true,
  supportList: null,
  totalSupports: null,
  supportDetails: null,
  supportChat: null,
  dashboard: null,
  driversList: null,
  totalDrivers: null,
  driverDetail:null,
};
const AdminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case DASHBOARD:
      return {
        ...state,
        dashboard: action?.payload,
      };
    case CHANGE_THEME_MODE:
      return {
        ...state,
        themeMode: action?.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        userList: action?.payload?.users,
        totalUser: action?.payload?.totalUsers,
      };
      case GET_ALL_DRIVERS:
        return {
          ...state,
          driversList: action?.payload?.users,
          totalDrivers: action?.payload?.totalUsers,
        };
    case VIEW_USER_DETAIL:
      return {
        ...state,
        userDetail: action?.payload,
      };
      case VIEW_DRIVER_DETAIL:
        return {
          ...state,
          driverDetail: action?.payload,
        };
    case GET_STATICS:
      const roomcategories = action?.payload?.roomcategories?.map((ob) => {
        return { label: ob.roomcategory, value: ob._id };
      });
      action.payload.roomcategories = roomcategories;
      return {
        ...state,
        statics: action?.payload,
      };
    case GET_PUBLIC_ROOMS:
      return {
        ...state,
        publicRooms: action?.payload?.rooms,
        totalRooms: action?.payload?.totalRooms,
      };
    case GET_SUPPORTS:
      return {
        ...state,
        supportList: action?.payload.supports,
        totalSupports: action?.payload.totalSupports,
      };
    case GET_SUPPORT_DETAILS:
      return {
        ...state,
        supportDetails: action?.payload,
      };

    default:
      return state;
  }
};
export default AdminReducer;
