import { IMAGES, SVGICON } from "../constant/theme";
import Layout from "../components/layouts/Layout";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

/// Image
import defaultImage from "../../images/placholder-img-admin1.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getSupportDetails,
  sendSupportMesage,
} from "../../store/actions/AdminAction";

const SupportDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const supportDetails = useSelector(
    (state) => state.adminReducer.supportDetails
  );
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL + "/";
  const [text, setText] = useState("");

  useEffect(() => {
    dispatch(getSupportDetails(id));
  }, []);

  const sendMessage = () => {
    const messageObject = {
      sender: 0,
      message: text,
    };
    dispatch(sendSupportMesage(id, messageObject));
    setText("");
  };

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Support Details"
          pageTitle={"Support Details"}
          parentTitle={"Support Management"}
        />

        <div className="card">
          <div className="card-header border-0 mb-0">
            <h4 className="heading mb-0">{supportDetails?.subject}</h4>
          </div>
          <div className="card-body pt-0">
            <div className="chat-box-area style-1 dz-scroll" id="chartBox2">
              <div className="media">
                <div className="message-received w-auto">
                  <div className="d-flex">
                    <img
                      src={
                        supportDetails?.sender_id?.profile_image
                          ? IMG_URL + supportDetails?.sender_id?.profile_image
                          : defaultImage
                      }
                      className="avatar rounded-circle"
                      alt="User Image"
                    />
                    <div className="ms-1 text">
                      <p className="mb-1">{supportDetails?.message}</p>
                      <span>
                        {moment(supportDetails?.createdAt).format("hh:mm a")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {supportDetails?.chat?.map((chatObj) => {
                if (chatObj?.sender == 1 || chatObj?.sender == 2) {
                  return (
                    <div className="media">
                      <div className="message-received w-auto">
                        <div className="d-flex">
                          <div className="ms-1 text">
                            <p className="mb-1">{chatObj?.message}</p>
                            <span>
                              {moment(supportDetails?.createdAt).format(
                                "hh:mm a"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (chatObj?.sender == 0) {
                  return (
                    <div className="media justify-content-end align-items-end ms-auto">
                      <div className="message-sent w-auto">
                        <p className="mb-1">{chatObj?.message}</p>
                        <span className="fs-12 mb-3">
                          {moment(chatObj?.createdAt).format("hh:mm a")}
                        </span>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <div className="message-send">
              <div className="type-massage style-1">
                <div className="input-group">
                  <textarea
                    onChange={(e) => setText(e.target.value)}
                    rows="1"
                    className="form-control"
                    placeholder="Type Here..."
                    value={text}
                  ></textarea>
                </div>
              </div>
              <button
                onClick={sendMessage}
                type="button"
                className="btn btn-primary p-2"
                disabled={text == "" ? true : false}
              >
                Send {SVGICON.Attachment}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupportDetails;
