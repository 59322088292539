export const CHANGE_THEME_MODE = "CHANGE_THEME_MODE";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const VIEW_USER_DETAIL = "VIEW_USER_DETAIL";
export const UPDATE_PROFILE_DETAIL = "UPDATE_PROFILE_DETAIL";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const GET_STATICS = "GET_STATICS";
export const CREATE_PUBLIC_ROOM = "CREATE_PUBLIC_ROOM";
export const GET_PUBLIC_ROOMS = "GET_PUBLIC_ROOMS";
export const GET_SUPPORTS = "GET_SUPPORTS";
export const GET_SUPPORT_DETAILS = "GET_SUPPORT_DETAILS";
export const GET_SUPPORT_CHAT = "GET_SUPPORT_CHAT";
export const DASHBOARD = "DASHBOARD";
export const GET_ALL_DRIVERS = "GET_ALL_DRIVERS";
export const VIEW_DRIVER_DETAIL = "VIEW_DRIVER_DETAIL";
export const UPDATE_DRIVER_DETAIL = "UPDATE_DRIVER_DETAIL";
export const GET_FAQS = "GET_FAQS";
export const ADD_FAQS = "ADD_FAQS";
export const GET_FAQ_BY_ID = "GET_FAQ_BY_ID";
export const REMOVE_FAQ = "REMOVE_FAQ";
export const UPDATE_FAQS = "UPDATE_FAQS";

