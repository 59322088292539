import { toast } from "react-toastify";
import API from "../../services/Api";
import {
  CHANGE_THEME_MODE,
  CREATE_PUBLIC_ROOM,
  GET_ALL_USERS,
  GET_PUBLIC_ROOMS,
  GET_STATICS,
  START_LOADING,
  STOP_LOADING,
  UPDATE_PROFILE_DETAIL,
  VIEW_USER_DETAIL,
  GET_SUPPORTS,
  GET_SUPPORT_DETAILS,
  GET_SUPPORT_CHAT,
  DASHBOARD,
  GET_ALL_DRIVERS,
  VIEW_DRIVER_DETAIL,
  UPDATE_DRIVER_DETAIL,
  GET_FAQS,
  ADD_FAQS,
  GET_FAQ_BY_ID,
  REMOVE_FAQ,
  UPDATE_FAQS,
} from "../Constant/constant";

export const dashboardApi = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get("/dashboard");
    if (res?.data?.success) {
      dispatch({
        type: DASHBOARD,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const changeThemeMode = (themeMode) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_THEME_MODE,
      payload: themeMode,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAllUsers = (page, limit, search, data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getUsers?page=${page == undefined ? 1 : page}&limit=${limit}&search=${
        search ? search : undefined
      }`,
      data
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_USERS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const getAllDrivers = (page, limit, search, data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getDrivers?page=${page == undefined ? 1 : page}&limit=${limit}&search=${
        search ? search : undefined
      }`,
      data
    );
    console.log(res,'resres');
    if (res?.data?.success) {
      dispatch({
        type: GET_ALL_DRIVERS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const viewUserDetail = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/getUserDetails?user_id=${id}`);
    if (data?.success) {
      dispatch({
        type: VIEW_USER_DETAIL,
        payload: data.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const viewDriverDetail = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const { data } = await API.get(`/getDriverDetails?user_id=${id}`);
    if (data?.success) {
      dispatch({
        type: VIEW_DRIVER_DETAIL,
        payload: data.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const updateUserProfile = (data) => async (dispatch) => {
  try {
    const res = await API.put("/editUserDetails", data);
    if (res?.data.success) {
      dispatch({
        type: UPDATE_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updateDriverProfile = (data) => async (dispatch) => {
  try {
    const res = await API.put("/editDriverDetails", data);
    if (res?.data.success) {
      dispatch({
        type: UPDATE_DRIVER_DETAIL,
        payload: res?.data?.data,
      });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const deleteUserAcc = (id, navigate) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const response = await API.delete(`/deleteUserAccount?user_id=${id}`);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
      navigate("/user-management");
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(response?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const deleteDirverAcc = (id, navigate) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const response = await API.delete(`/deleteDriverAccount?user_id=${id}`);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
      navigate("/driver");
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(response?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const changePassword = (data) => async (dispatch) => {
  const res = await API.put("/changeUserPass", data);
  if (res?.data.success) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};
export const deactivateAccount = (data) => async (dispatch) => {
  try {
    const res = await API.put("/deactivateUserAccount", data);
    if (res?.data.success) {
      dispatch(viewUserDetail(data.userId));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const deactivateDriverAccount = (data) => async (dispatch) => {
  try {
    const res = await API.put("/deactivateDriverAccount", data);
    if (res?.data.success) {
      dispatch(viewDriverDetail(data.userId));
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const changePasswordDriver = (data) => async (dispatch) => {
  const res = await API.put("/changeDriverPass", data);
  if (res?.data.success) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};

export const getStatics = () => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get("/getStatics");
    if (res?.data?.success) {
      dispatch({
        type: GET_STATICS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getPublicRooms = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getPublicRooms?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_PUBLIC_ROOMS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const createPublicRoom = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.post("/createPublicRoom", data);
    if (res?.data?.success) {
      dispatch({
        type: CREATE_PUBLIC_ROOM,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
      dispatch(getPublicRooms(1, 10, undefined));
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const getSupports = (page, limit, search) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(
      `/getSupports?page=${
        page == undefined ? 1 : page
      }&limit=${limit}&search=${search ? search : undefined}`
    );
    if (res?.data?.success) {
      dispatch({
        type: GET_SUPPORTS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};
export const getSupportDetails = (support_id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  try {
    const res = await API.get(`/getSupportDetails?support_id=${support_id}`);
    if (res?.data?.success) {
      dispatch({
        type: GET_SUPPORT_DETAILS,
        payload: res?.data?.data,
      });
      dispatch({ type: STOP_LOADING, payload: false });
    } else {
      toast.error(res?.data?.message);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error);
    dispatch({ type: STOP_LOADING, payload: false });
  }
};

export const sendSupportMesage =
  (support_id, messageObject) => async (dispatch) => {
    try {
      const res = await API.post("/sendAdminSupportMessage", {
        message: messageObject,
        support_id,
      });
      if (res?.data?.success) {
        dispatch({
          type: GET_SUPPORT_DETAILS,
          payload: res?.data?.data,
        });
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

export const changeSupportStatus = (support_id, status) => async (dispatch) => {
  try {
    const res = await API.post("/changeSupportStatus", {
      status,
      support_id,
    });
    if (res?.data?.success) {
      dispatch(getSupports());
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const driverVerify = (data) => async (dispatch) => {
  try {
    const res = await API.put("/verifyUnverifyDriver", data);
    
    console.log(res?.data?.data,"qqqqqqqqqqqqqqqqqqqqqq=====");
    if (res?.data.success) {
      // dispatch({
      //   type: UPDATE_DRIVER_PROFILE,
      //   payload: res?.data?.data,
      // });
      dispatch(getAllDrivers())
      toast.success(res?.data?.message)
     
    } 
    else {
      toast.error(res?.data.message);
    }
   
  } 
  
  catch (error) {
    toast.error(error);
  }
};


// =================== Add Faqs =================

export const  getFaqs = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/getFaq?limit=${details?.limit}&page=${details?.page}&name=${details?.search}`);

    if (data?.status == 200) {
      dispatch({
        type: GET_FAQS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// =============  Add Faqs ==================

export const  AddFaqs = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/addFAQ`,details);

    if (data?.status == 200) {
      dispatch({
        type: ADD_FAQS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// ==== Update Faqs =======

export const  UpdateFaqs = (details) => async (dispatch) => {
  try {
    const data = await API.put(`/updateFaq`,details);

    if (data?.status == 200) {
      dispatch({
        type: UPDATE_FAQS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// ====== remove Faqs ===========

export const  RemoveFaqs = (details) => async (dispatch) => {
  try {
    const data = await API.delete(`/deleteFaq?id=${details?.id}`);

    if (data?.status == 200) {
      dispatch({
        type: REMOVE_FAQ,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};
