import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";

const UserManagement = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL + "/";
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state?.adminReducer?.userList);
  const totalUsers = useSelector((state) => state?.adminReducer?.totalUser);
  const lastIndex = page * limit;
  const npage = Math.ceil(totalUsers / limit);
  const number = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    dispatch(getAllUsers(page, limit, search));
  }, [page, limit, search]);

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const invite = useRef();
  const employe = useRef();
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Support Management"
        pageTitle={"Support Management"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Support Management</h4>
                    <Search setSearch={setSearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>User Name</th>
                          <th>Email Address</th>
                          <th>Contact Number</th>
                          <th>Last Login</th>
                          <th>Login Type</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUsers?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span>{index + lastIndex - limit + 1}</span>
                            </td>
                            <td>
                              <div className="products">
                                <img
                                  src={
                                    item?.profile_image
                                      ? IMG_URL + item?.profile_image
                                      : Placholder
                                  }
                                  className="avatar avatar-md"
                                  alt=""
                                />
                                <div
                                  className="pointerUser"
                                  onClick={() =>
                                    navigate(`/user-detail/${item._id}`)
                                  }
                                >
                                  <h6>{item?.full_name}</h6>
                                  <span>Customer</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="text-primary ">
                                {item?.email}
                              </span>
                            </td>
                            <td>
                              <span>{item?.phone_number}</span>
                            </td>
                            <td>
                              <span>
                                {" "}
                                {moment(item?.lastLogin).format("Do MMMM YYYY")}
                              </span>
                            </td>
                            <td>
                              <span>
                                {item?.role == 0
                                  ? "Genreal"
                                  : item?.role == 1
                                  ? "Apple"
                                  : item?.role == 2
                                  ? "Google"
                                  : "Others"}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge light border-0 ${
                                  item.status === 1
                                    ? "badge-success"
                                    : "badge-danger"
                                } `}
                              >
                                {item.status === 1 ? "Active" : "In-active"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {totalUsers < lastIndex ? totalUsers : lastIndex} of{" "}
                        {totalUsers} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />
    </Layout>
  );
};

export default UserManagement;
