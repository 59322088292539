import React from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
const Loader = () => {
  const themeMode = useSelector((state) => state.adminReducer.themeMode);
  const isLoading = useSelector((state) => state?.loderReducer?.loading);
  return (
    <>
      {isLoading && (
        <div
          className={`${
            themeMode ? "loader-style" : "loader-style-dark-theme"
          } container-fluid`}
        >
          <FadeLoader
            className="fade-loader"
            loading={isLoading}
            color={"#AB91ED"}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
