import { Route, Routes } from "react-router-dom";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Login from "./jsx/pages/Login";
import Dashboard from "./jsx/pages/Dashboard";
import { ToastContainer } from "react-toastify";
import UserManagement from "./jsx/pages/UserManagement";
import SupportManagement from "./jsx/pages/SupportManagement";
import Announcements from "./jsx/pages/Announcements";
import Notifications from "./jsx/pages/Notifications";
import ManageFaq from "./jsx/pages/ManageFaq";
import ChangePassword from "./jsx/pages/ChangePassword";
import PrivateRoute from "./jsx/routes/PrivateRoute";
import UserDetail from "./jsx/pages/UserDetail";
import Loader from "./jsx/components/elements/Loader";
import SupportDetails from "./jsx/pages/SupportDetails";
import Drivers from "./jsx/pages/Drivers";
import DriverDetail from "./jsx/pages/DriverDetail";

function App() {
  return (
    <div className="vh-100">
      <ToastContainer limit={1} autoClose={2000} />
      <Loader />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/driver" element={<Drivers />} />
          <Route path="/support-management" element={<SupportManagement />} />
          <Route path="/support-details/:id" element={<SupportDetails />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/manage-faq" element={<ManageFaq />} />
          <Route path="/user-detail/:id" element={<UserDetail />} />
          <Route path="/driver-detail/:id" element={<DriverDetail />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
