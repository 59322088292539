import React from "react";

import DepositlineChart from "./DepositlineChart";
import AllProjectDonutChart from "./AllProjectDonutChart";
import { SVGICON } from "../../constant/theme";

const CardWidget = ({ dashboard }) => {
  return (
    <>
      <div className="col-xl-4 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6>Total Users</h6>
                <h3>{dashboard?.totalUsers}</h3>
              </div>
              <div className="icon-box bg-primary-light">{SVGICON.User}</div>
            </div>
            <DepositlineChart chartcolor="var(--primary)" />
          </div>
        </div>
      </div>
      {/* <div className="col-xl-3 col-sm-6">
                <div className="card same-card">
                    <div className="card-body d-flex align-items-center  py-2">                        
                        <AllProjectDonutChart />
                        <ul className="project-list">
                            <li><h6>All Projects</h6></li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="#3AC977"/>
                                </svg>{" "}
                                Compete
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="var(--primary)"/>
                                </svg>{" "}
                                Pending
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="var(--secondary)"/>
                                </svg>{" "}
                                Not Start
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
      <div className="col-xl-4 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6>Verified Users</h6>
                <h3>{dashboard?.verifiedUsers}</h3>
              </div>
              <div className="icon-box bg-primary-light">
                {SVGICON.Performance}
              </div>
            </div>
            <DepositlineChart chartcolor="#FF5E5E" />
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6>New Registration</h6>
                <h3>{dashboard?.newRegistration}</h3>
              </div>
              <div className="icon-box bg-primary-light">
                {SVGICON.CalendarList}
              </div>
            </div>
            <DepositlineChart chartcolor="var(--primary)" />
          </div>
        </div>
      </div>

      {/* <div className="col-xl-4 col-sm-6 same-card">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>New Registration</h6>
                                <h3>205</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.CalendarList}
                            </div>
                        </div>
                        <div className="progress-box mt-0">
                            <div className="d-flex justify-content-between">
                                <p className="mb-0">Tasks Not Finished</p>
                                <p className="mb-0">20/28</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar bg-primary" style={{width:"50%", height:"5px", borderRadius:"4px"}} ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default CardWidget;
