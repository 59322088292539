import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
import CreatePublicRoomModal from "../components/modals/CreatePublicRoomModal";
import { AddFaqs, GetFaqbyid, RemoveFaqs, UpdateFaqs, getFaqs } from "../../store/actions/AdminAction";
import { Modal,Button } from "react-bootstrap";
import { Formik,ErrorMessage } from "formik";
import * as Yup from "yup"
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  question: Yup.string()
    .required('Required')
    .min(2, 'Question must be at least 2 characters'),
  answer: Yup.string()
    .required('Required')
    .min(5, 'Answer must be at least 5 characters'),
});

const PublicRooms = () => {
  
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [flag, setflag] = useState(true);
  const [Faqdetails, setFaqdetails] = useState([]);
  const [UpdateModal, setUpdateModal] = useState(false);
  const [showd, setshowd] = useState(false);
  const [Id, setid] = useState("");
  const [pagi, setpagi] = useState("");
  const [currData,setCurrData]=useState()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewVal, setViewVal] = useState(false);
  

  const lastIndex = page * limit;
  const npage = Math.ceil(
    (pagi?.totalCount == undefined ? 0 : pagi?.totalCount) /
      limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }


  useEffect(() => {
    const getdata = async() => {
     dispatch(getFaqs({limit:limit,search:search,page:page})).then((res)=> {
      console.log(res);
      if(res?.data?.status_code === 200){
        setFaqdetails(res?.data?.data?.users);
        setpagi(res?.data?.data);
        setflag(!flag)
      }
     })
    }
    getdata();
  },[limit,search,page,flag]);

  
   
   const handleDelete = async() => {
    dispatch(RemoveFaqs({id:Id})).then((res) => {
      console.log(res);
      if(res?.data?.status_code === 200){
        toast.success(res?.data?.message);
        setshowd(false);
        setflag(!flag)
       }
    });
   }

  const editFaq = async(values)=>{
    try{
      const data =  await dispatch(UpdateFaqs({question:values?.question,
       answer:values?.answer,
       id:currData._id}));
      console.log(data);
      if(data?.data?.status_code === 200){
       toast.success(data?.data?.message);
       setUpdateModal(false)
       setflag(!flag);
      }
   }catch(error){
     console.log(error);
   }
  }





 
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Manage FAQ"
        pageTitle={"Manage FAQ"}
        parentTitle={"Home"}
      />

      <CreatePublicRoomModal viewVal={viewVal} setViewVal={setViewVal}/>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Manage FAQ</h4>
                    <div className="srearch-btn">
                      <div className="tbl-caption d-flex justify-content-end text-wrap align-items-center pl-0-btn">
                        <button
                          className="btn btn-primary"
                          onClick={() => setViewVal(true)}
                        >
                         ADD
                        </button>
                        <div className="searchbarpublic">
                          <Search setSearch={setSearch} setPage={setPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer h-fixed-505"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Question</th>
                          <th>Answer</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Faqdetails?.map((details,index) => {
                           const serialNumber = (page - 1) * limit + index + 1;
                          return(
                      
                        <tr >
                          <td>{serialNumber}</td>
                          <td>{details?.question}</td>
                          <td>{details?.answer}</td>
                          <td>
                            <span className="badge light border-0 pl-0">
                             
                              <button className="badge border-0 badge-success"  onClick={() => {
                                setCurrData(details)
                                setUpdateModal(true)
                              }
                                }>
                                Update
                              </button>
                            </span>
                            <span className="">

                              <button className="badge border-0 badge-danger" onClick={() => {setshowd(true);  setid(details?._id)}}>
                                Remove
                              </button>
                            </span>
                          </td>
                        </tr>
                            )
                          })}
                      </tbody>
                 </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                      Showing {lastIndex - limit + 1} to{" "}
                          {pagi?.totalCount &&
                          pagi?.totalCount < lastIndex
                            ? pagi?.totalCount
                            : lastIndex}{" "}
                          of{" "}
                          {pagi?.totalCount &&
                            pagi?.totalCount}{" "}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Modal show={UpdateModal}  onHide={() => setUpdateModal(false)} animation={false} id='add-modal' size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal">
        <Modal.Header closeButton onHide={() => setUpdateModal(false)}></Modal.Header>
      <Modal.Body >
        <h4 className="text-center mb-0 delete_content">Update Question</h4>
        <div className="card-body p-0">
          <div className="basic-form">
            <Formik
              initialValues={{ question:currData?.question,answer:currData?.answer }}
              validationSchema={validationSchema}
              onSubmit={(values) => {editFaq(values)}}
            >
              {({
               values, handleChange,handleSubmit
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label>Question</label>
                      <input
                        type="text"
                        name="question"
                        className="form-control"
                        placeholder="Type Here..."
                        value={values?.question}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="question" component="div" className="text-danger"/>
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Answer</label>
                      <textarea
                        type="text"
                        row="6"
                        name="answer"
                        className="form-control"
                        placeholder="Type Here..."
                        value={values?.answer} 
                        onChange={handleChange}
                      />
                      <ErrorMessage name="answer" component="div" className="text-danger"/>
                      
                    </div>
                  
                  </div>

                  <div className="d-flex justify-content-center delete_acc_btns">
                    <button
                    
                      type = 'submit'
                      className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
                      
                    >
                      Update
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
      </Modal>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Faq
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" /> */}
    </Layout>
  );
};

export default PublicRooms;
