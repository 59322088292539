import React, { useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Adminlogin,
  loadingToggleAction,
} from "../../store/actions/AuthActions";

import bg6 from "../../images/background/hippo-admin-banner.jpg";
import { useFormik } from "formik";
import { ThemeContext } from "../../context/ThemeContext";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import LogoBlack from "../../images/logo/logo_hippo_theme.svg";
import LogoFront from "../../images/logo/side_log_icon.svg";
function Login(props) {
  useEffect(() => {
    sessionStorage.setItem("themeMode", true);
  }, []);

  const [heartActive, setHeartActive] = useState(true);
  const [keepLogin, setKeepLogin] = useState(false);
  const { changeBackground } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var cookies = new Cookies();
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is Required*";
    }
    if (!values.password) {
      errors.password = "Password is Required*";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      dispatch(loadingToggleAction(true));
      dispatch(Adminlogin(values, navigate, keepLogin));
    },
  });
  useEffect(() => {
    changeBackground({ value: "light", label: "light" });
    if (
      window.sessionStorage.getItem("adminToken") ||
      cookies.get("adminToken")
    ) {
      if (
        cookies.get("adminToken") &&
        !window.sessionStorage.getItem("adminToken")
      ) {
        window.sessionStorage.setItem("adminToken", cookies.get("adminToken"));
        window.sessionStorage.setItem("name", cookies.get("name"));
        window.sessionStorage.setItem("email", cookies.get("email"));
      }
      window.location.href = "/dashboard";
    }
  }, []);

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div>
          <div className="row gx-0">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                style={{ maxHeight: "653px" }}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    dir: "ltr",
                  }}
                >
                  <div className="login-form style-2">
                    <div className="card-body">
                      <div className="logo-header">
                        <Link to={"#"} className="logo">
                          <img src={LogoFront} alt="" className="login-form-logo" />
                        </Link>
                        <Link to={"#"} className="logo">
                          <img src={LogoBlack} alt="" className="logo-width" />
                        </Link>

                        {/* <Link to={"#"} className="logo logo_new">
                          <img
                            // src={LogoWhite}
                            src={LogoBlack}
                            alt=""
                            className="width-230 dark-logo"
                          />
                        </Link> */}
                      </div>
                      <div className="nav nav-tabs border-bottom-0">
                        <div className="tab-content w-100" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-personal"
                          >
                            {props.errorMessage && (
                              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                                {props.errorMessage}
                              </div>
                            )}
                            {props.successMessage && (
                              <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                                {props.successMessage}
                              </div>
                            )}
                            <form
                              className=" dz-form pb-3"
                              onSubmit={formik.handleSubmit}
                            >
                              <h3 className="form-title m-t0">
                                Personal Information
                              </h3>
                              <div className="dz-separator-outer m-b5">
                                <div className="dz-separator bg-primary style-liner"></div>
                              </div>
                              <p>
                                Enter your e-mail address and your password.{" "}
                              </p>
                              <div className="form-group mb-3">
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.email && (
                                  <div className="text-danger fs-12">
                                    {formik.errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="form-group mb-3">
                                <input
                                  type="password"
                                  name="password"
                                  className="form-control"
                                  value={formik.values.password.trim()}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.password && (
                                  <div className="text-danger fs-12">
                                    {formik.errors.password}
                                  </div>
                                )}
                              </div>
                              <div className="form-group text-left mb-5">
                                <button
                                  type="submit"
                                  className="btn btn-primary dz-xs-flex m-r5"
                                >
                                  login
                                </button>
                                <span className="form-check d-inline-block ms-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="check1"
                                    name="example1"
                                    onChange={(e) =>
                                      setKeepLogin(e.target.checked)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="check1"
                                  >
                                    Remember me
                                  </label>
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                        <div className="col-lg-12 text-center">
                          <span>
                            {" "}
                            ©2023 Hippo.{" "}
                            {/* <span
                              className={`heart ${
                                heartActive ? "" : "heart-blast"
                              }`}
                              onClick={() => setHeartActive(!heartActive)}
                            ></span> */}
                            All rights reserved.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div
                className="bg-img-fix overflow-hidden"
                style={{
                  background: "#fff url(" + bg6 + ")",
                  height: "100vh",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={bg6} alt="Banner"/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
